body {
    margin: 0 auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}

*, *:after, *:before {
    box-sizing: border-box;
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

a {
    text-decoration: none;
    color: inherit;
}

nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


.header, footer{
    background-color: #333;
    color: #fff;
}


.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header-logo {
    font-size: 1.8rem;
    font-weight: 800;
}

.header-link {
    margin: 0 10px;
    position: relative;
    transition: color 0.3s linear;
}

.header-link:hover, .header-link:active {
    color: #888;
}

.shop-cart-button {
    margin-right: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: color 0.3s linear, transform 0.3s linear;
}

.shop-cart-button:hover, .shop-cart-button.active {
    color: #dc3d3d;
    transform: scale(1.5);
}

.header-inner .shop-cart {
    position: absolute;
    top: 58px;
    right: 0;
    width: 450px;
    padding: 15px;
    padding-bottom: 0;
    background: #f5f5f5;
    -webkit-box-shadow: 4px 5px 15px -7px #333;
    box-shadow: 4px 5px 15px -7px #333;
    z-index: 1000;
}

.header-inner .shop-cart .item {
    color: #333;
    width: 100%;
    float: left;
    margin-bottom: 15px;
}

.header-inner .shop-cart .item img {
    width: 70px;
    float: left;
    margin-right: 20px;
}

.header-inner .shop-cart .item h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.header-inner .shop-cart .item b {
    font-size: 1rem;
    color: #000;
    font-weight: 800;
}

.header-inner .shop-cart .no-orders {
    color: #333;
    text-align: center;
}

.header-inner .shop-cart .item .delete-icon {
    font-size: 1rem;
    color: #ca5252;
    float: right;
    position: relative;
    top: -20px;
    cursor: pointer;
    transition: color, transform 0.3s linear;
}

.header-inner .shop-cart .item .delete-icon:hover {
    color: #d83030;
    transform: scale(1.5);
}

.header-inner .shop-cart .sum {
    text-align: center;
    width: 100%;
    font-weight: 700;
    font-size: 1.4rem;
    color: #333;
}

.checkmark-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
.checkmark-animation.visible {
    display: block;
}
  
.checkmark {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.checkmark__stem,
.checkmark__kick {
    background-color: rgb(144, 238, 144); 
    position: absolute;
}
  
.checkmark__stem {
    width: 6px;
    height: 31px;
    left: 25px;
    top: 8px;
    transform: rotate(360deg);
}
  
.checkmark__kick {
    width: 5px;
    height: 16px;
    left: 21px;
    top: 26px;
    transform: rotate(-45deg);
}

.delete-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 16px;
    border-radius: 10px;
    z-index: 1000;
}

.header-2 {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: #555;
    color: #fff;
}

.header-2-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding: 3px;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.header-2-link:hover {
    background-color: #666;
}

.header-2-1 {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

#search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
}

.header-2-1 a {
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
}

.header-2-1-img {
    width: 25px;
    height: 25px;
    transition: transform 0.5s ease;
}

.header-2-1-img:hover {
    transform: scale(1.2);
}

.categories div{
    display: inline-block;
    background: #333;
    border-radius: 50px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #fff;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all, transform 0.3s linear;
}

.categories div:hover{
    border-color: silver;
    transform: scale(1.1);
}

.main-items {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 0;
}

.main-items .item {
    width: 30%;
    margin-bottom: 50px;
    background-color: #f5f5f5;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;
}

.main-items .item img {
    width: 100%;
    border-radius: 10px 10px 0 0;
    transition: 500ms ease;
    cursor: pointer;
}

.full-item img {
    width: 400px;
    border-radius: 10px 10px 0 0;
    transition: 500ms ease;
    cursor: pointer;
}

.full-item img:hover, 
.main-items .item img:hover {
    transform: scale(1.03);
}

.main-items h2, .full-item h2,
.main-items p, .full-item p,
.main-items b, .full-item b {
    margin: 10px 20px;
    color: #333;
}

.main-items b, .full-item b {
    color: #5fa36a;
}

.full-item {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    overflow: auto;
}

.full-item > div {
    padding-top: 20px;
    margin: 10% auto;
    width: 600px;
    text-align: center;
    position: relative;
    background: #fff;
    border-radius: 9px;
}

.create-order {
    background-color: #333; 
    border: none;
    color: white;
    padding: 15px 32px;
    float: right;
    margin: 4px 2px;
    cursor: pointer;
}
  
.create-order:hover {
    background-color: #555; 
}

.item .buttons,
.full-item .buttons {
    position: absolute;
    right: 5px;
    bottom: 3px;
    display: flex;
    align-items: center; 
}

.item .add-to-cart,
.full-item .add-to-cart {
    background: #333;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 500ms ease;
}

.item .add-to-cart:hover,
.full-item .add-to-cart:hover {
    background: #555;
    transform: scale(1.2) translateY(-5px);
}

.item .question-mark,
.full-item .question-mark {
    background-color: #ccc;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
}

.item .question-mark:hover {
    background-color: #aaa;
    transform: scale(1.2) translateY(-5px);
    transition: 500ms ease;
}
  
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    position: relative;
}

.modal-content .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.modal-content .close:hover,
.modal-content .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.order-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.order-form label {
    display: block;
    margin-bottom: 10px;
}

.order-form input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}

.order-form button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.order-form button:hover {
    background-color: #555;
}  

.titles {
    text-align: center;
    font-size: 2rem;
    color: #555; 
    text-transform: uppercase;
    letter-spacing: 2px; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    transition: color 0.3s ease, text-shadow 0.3s ease; 
}

.titles:hover {
    color: #666; 
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5); 
}

.header-2 > * {
    display: inherit;
}

.header-2{
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: #555;
    color: #fff;
}

.header-2-link {
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #555;
    padding: 5px 5px;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.header-2-link:hover {
    background-color: #666;
}

.header-2-1 {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

#search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; 
    box-sizing: border-box;
}

.header-2-1 a {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    right: 10px;
    top: 4px;
    cursor: pointer;
}

.header-2-1-img{
    width: 25px;
    height: 25px;
    transition: 0.5s ease;
}

.header-2-1-img:hover{
    transform: scale(1.2);
}

.product-page {
    font-family: Arial, sans-serif;
  }
  
  .scrolling-navigation {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .scroll-button {
    padding: 12px 24px;
    border: none;
    background-color: #5a9bd4;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.3s ease;
  }
  
  .scroll-button:hover {
    background-color: #2a6c9a;
  }
  
  .section-detail,
  .section-specs,
  .section-reviews,
  .section-qa {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #333;
  }
  

.banner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}
  
.banner-content {
    text-align: center;
}
  
.close-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 3px;
}
  
.close-icon {
    font-size: 1.5rem;
    color: #ff4444;
    transition: color 0.5s ease;
}
  
.close-btn:hover .close-icon {
    color: #fa0000;
}
  
.banner-image {
    height: 400px;
}
  
.banner-text {
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: 5px;
}
  
.banner-button {
    margin: 10px;
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(to right, #3897F0, #C63397);
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
  
.banner-button:hover {
    background: linear-gradient(to right, #2F80ED, #9B298F);
}  

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.loader {
    font-size: 35px; 
    color: #000; 
    text-transform: uppercase; 
    position: relative;
}
  
.loader::before {
    content: '';
    position: absolute;
    top: -15px;
    left: -15px;
    right: -15px;
    bottom: -15px;
    border: 3px solid #000;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 0.5s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.slider {
    margin-top: 10px;
    position: relative;
}
  
.slide {
    position: relative;
}

.slide img {
    width: 100%;
    height: 600px;
    display: block;
    margin: 0 auto; 
}
  
.text-overlay {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 20px;
    text-align: center;
}
  
.text-overlay p {
    font-size: 0.9rem;
    margin: 0;
}
  
.prev-slide, .next-slide {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
    z-index: 1; 
}
  
.prev-slide {
    left: 20px;
}
  
.next-slide {
    right: 20px;
}  

.Home-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step {
    text-align: center;
    border-left: 8px dashed #333;
    border-right: 8px dashed #333;
    margin-bottom: 20px;
}

.Home-4-number {
    font-size: 5rem;
    color: #333;
    font-weight: 700;
    text-decoration: underline #333;
}

.Home-4-step-image {
    width: 60%;
    height: auto;
    border: 3px solid #333;
    border-radius: 50%;
}

.Home-4-step-text {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
}

.hiden-step {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.visible-step {
    opacity: 1;
}

.Home-6 {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
}

.Home-6-review {
    width: 350px;
    height: 350px;
    padding: 10px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in-out;
}

.Home-6-review:hover, .Home-6-review:active {
    transform: scale(1.03);
}

.Home-6-review img {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.Home-6-review p {
    font-size: 0.9rem;
    color: #fff;
}

.Home-6-review h4 {
    font-size: 1.1rem;
    margin: 3px 0 0;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.Home-8 {
    margin-top: 30px;
  }
  
.Home-8 h2 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 20px;
}
  
.Home-8 ul {
    list-style-type: none;
    padding: 0;
}
  
.question {
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    background-color: #333;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
    width: 100%;
    box-sizing: border-box; 
}
  
.question:hover {
    background-color: #444;
}
  
.answer {
    color: #fff;
    background-color: #333;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}  

.visible-link{
    color: #9f9f9f;
    text-decoration: underline 2px solid #666;
    font-weight: 800; 
}

.comment-box {
    font-style: italic;
    font-size: 0.9rem;
}

.comment-box:before {
    content: "“";
    font-size: 1rem;
    vertical-align: middle;
}

.comment-box:after {
    content: "”";
    font-size: 1rem;
    vertical-align: middle;
}

.About-1, .Contacts-1, .Service-1, .Catalog-1{
    color: #333;
    text-align: center;
}

.About-1-img, .Contacts-1-img, .Service-1-img, .Catalog-1-img{
    position: relative;
}

.About-1 img, .Contacts-1 img, .Service-1 img, .Catalog-1 img{
    width: 100%;
    max-height: 25vh;
    object-fit: cover;
}

.About-1-txt, .Contacts-1-txt, .Service-1-txt, .Catalog-1-txt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 0.8em;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}

.About-1-txt h1, .Contacts-1-txt h1, .Service-1-txt h1{
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
}

.About-2, .Home-5{
    color: #333;
    margin-top: 15px;
    display: flex;
    width: 100%;
    height: 500px;
}

.About-2-img, .Home-5-img{
    width: 40%;
    height: 100%;
}
  
.About-2-txt, .Home-5-txt{
    font-size: 1.05rem;
    font-weight: 500;
    color: #333;
    padding-left: 8px;
    text-align: center;
    width: 63%;
}

.About-3, .Home-3{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.About-3-item, .Home-3-item {
    height: 100%;
    width: 30%;
    position: relative;
    text-align: center;
}

.About-3-img, .Home-3-img{
    border: 3px solid #333;
    width: 100%;
    height: auto;
    display: block;
}

.About-3-txt, .Home-3-txt{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.7);
    color: #333;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.About-4, .Home-9{
    text-align: center;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.About-4-item, .Home-9-item {
    width: 350px;
    height: 230px;
    padding: 15px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.About-4-item h1, .Home-9-item h1{
    font-size: 2.3rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
}

.About-4-item p, .Home-9-item p{
    font-size: 0.9rem;
    color: #fff;
}

.About-4-item:hover, .About-4-item:active,
.Home-9-item:hover, .Home-9-item:active {
    transform: scale(1.05);
}

.About-5 {
    color: #333;
    background: url('../public/img/About-5.jpg') center/cover;
    width: 100%;
    height: 350px;
    padding: 30px 0;
    text-align: center;
    color: #333;
}

.About-5 h2 {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 1.8rem;
    margin-bottom: 15px;
}

.About-5 p {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
}

.About-5 a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #333;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.About-5 a:hover, .about-us-5 a:active {
    background-color: #666;
}

.Contacts-2{
    color: #333;
    border: 3px solid #333;
    padding: 3px;
    display: flex;
    justify-content: space-between;
}

.Contacts-2-1 {
    width: 49%;
    color:#333;
}

.Contacts-2-1 form {
    display: flex;
    flex-direction: column;
}

.Contacts-2-1 label {
    display: block;
    margin-bottom: 8px;
}

.Contacts-2-1 input,
.Contacts-2-1 textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.Contacts-2-1 button {
    background-color: #333;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.Contacts-2-1 button:hover, .contacts-1-inner button:active {
    background-color: #666;
}

.Contacts-2-2 {
    width: 49%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-left: 3px solid #333; 
    font-weight: 500;
    color:#333;
}

.Contacts-2-2 img{
    margin-left: 10px;
    height: 25px;
}

.Contacts-3-1, .Home-7-1{
    width: 100%;
    height: 65vh;
    border: 1px solid #333;
}

.Service-2-1, .Service-3-1{
    display: flex;
    justify-content: space-around;
}

.Service-2 p, .Service-3 p, .Service-4 p{
    text-align: center;
    font-weight: 800;
    margin-bottom: 15px;
}

.Service-2-1-1, .Service-2-1-2,
.Service-3-1-1, .Service-3-1-2 {
    width: 45%;
    padding: 10px;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in-out;
}

.Service-2-1-1:hover, .Service-2-1-2:hover,
.Service-2-1-1:active, .Service-2-1-2:active,
.Service-3-1-1:hover, .Service-3-1-2:hover,
.Service-3-1-1:active, .Service-3-1-2:active {
    transform: scale(1.03);
}

.Service-2-1-1 h2, .Service-2-1-2 h2,
.Service-3-1-1 h2, .Service-3-1-2 h2{
    font-size: 1.8rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
    margin-bottom: 5px;;
}

.Service-2-1-1 p, .Service-2-1-2 p,
.Service-3-1-1 p, .Service-3-1-2 p{
    font-size: 0.8rem;
    color: #fff;
}

.Service-3-1-1 img, .Service-3-1-2 img {
    width: 50%;
    margin-bottom: 5px;
}

.Service-4-1 {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.tab {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: 1px solid #333;
    border-radius: 8px;
    cursor: pointer;
}

.tab:hover, .tab:active {
    background-color: #666;
}

.tab-content {
    display: none;
}

.tab-content h2 {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 10px;
}

.tab-content p {
    color: #666;
}

.show {
    display: block;
}

.Service-alert{
    text-align: center;
    margin-top: 15px;
    color: red;
    font-size: 1em;
    font-weight: 1000;
}

.footer-inner {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-item {
    width: 49%;
    margin-bottom: 15px;
}

.footer-item h3 {
    font-size: 1.2rem;
    color: #fff;
}

.footer-item ul {
    list-style: none;
    padding: 0;
}

.footer-item ul li, .footer-item a{
    color: #888;
    margin-bottom: 5px;
    transition: color 0.3s linear;
}

.footer-inner p{
    color: #888;
    margin-bottom: 5px;
    transition: color 0.3s linear;
}

.footer-item ul li, .footer-item a:hover{
    color: #fff;
}
  
.scroll-section-container {
    display: flex;
    justify-content: flex-start; 
    flex-wrap: wrap;
    gap: 15px; 
    margin: 20px 0;
    padding: 10px;
    background-color: #f4f4f4; 
    border-radius: 8px; 
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); 
}

.scroll-section-container button {
    background-color: transparent; 
    border: 2px solid #333; 
    border-radius: 4px;
    color: #333; 
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
    text-transform: uppercase;
    font-weight: 400;
    transition: color 0.3s, border-color 0.3s, transform 0.2s, box-shadow 0.3s;
}

.scroll-section-container button:hover {
    color: #555;
    border-color: #777; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transform: translateY(-2px);
}

.scroll-section-container button:active {
    color: #222; 
    border-color: #222; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    transform: translateY(1px); 
}

.scroll-section-container button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

#section1{
    width: 100%;
    display: flex;
    background-color: #f4f4f4;
    margin-bottom: 30px;
}

.section1-part1{
    max-width: 33%;
}

.section1-part2{
    min-width: 45%;
}

.section1-part3{
    max-width: 21%;
}

.product-image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; 
}
  
.main-image-wrapper {
    position: relative;
}
  
.main-image {
    cursor: pointer;
    object-fit: cover;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}
  
.main-image img {
    width: 100%;
}
  
.thumbnail-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin-top: 3px;
}
  
.thumbnail {
    cursor: pointer;
}
  
.thumbnail img {
    max-width: 90px;
    height: 80%;
}
  
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000; 
    animation: fadeIn 0.5s; 
}
  
.fullscreen img {
    max-width: 95%;
    max-height: 95%;
    object-fit: contain; 
}
  
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
  
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); 
    z-index: 999;
}

.product-description-container {
    padding: 5px;
}
  
.product-title-header {
    text-align: center;
}
  
.product-title-text {
    font-size: 2em;
    margin: 0;
    font-weight: 1000;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
  
.product-model-text {
    font-size: 2em;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 18px;
}
  
.product-info-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 18px;
    width: 100%;
}
  
.info-box-item {
    width: 32%; 
    height: 50px; 
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.info-box-item:hover {
    background-color: #333;
    color: #fff;
    border: none;
    transform: scale(1.05);
}
  
.info-box-text {
    text-align: center;
}
  
.product-price-header {
    text-align: center;
}
  
.price-title {
    font-size: 1.5em; 
}
  
.purchase-button {
    display: block;
    text-align: center;
    width: 280px;
    padding: 12px;
    margin: 0 auto;
    font-size: 1.2em;
    background-color: #333; 
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.5s ease;
}
  
.purchase-button:hover {
    transform: scale(1.05);
    background-color: #555;
}
  
.purchase-reasons-container {
    margin-top: 30px;
}

.reasons-title {
    text-align: center;
    margin-bottom: 15px;
    font-size: 1.4em;
}

.reasons-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.reason-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.8em;
    font-weight: 700;
    flex: 1 1 19%; 
    box-sizing: border-box; 
}

.reason-item .icon {
    font-size: 2em; 
    margin-bottom: 8px; 
}

  
.contact-section {
    margin-top: 25px;
    text-align: center;
}
  
.horizontal-line {
    border-top: 3px solid #555;
    margin-bottom: 35px;
}
  
.contact-question {
    font-size: 1.4em;
    margin: 15px 0;
}

.contact-form {
    margin: 20px 0;
}
  
.contact-input {
    width: 80%;
    padding: 8px 4px;
    border-radius: 6px;
    border: 1px solid #444;
    background-color: #f4f4f4;
    color: #333;
    font-size: 1.2em;
}
  
.contact-submit-button {
    padding: 12px 24px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 10px;
}
  
.contact-submit-button:hover {
    background-color: #555;
}
  
.contact-submission-status {
    margin-top: 10px;
    color: #4CAF50;
    font-size: 1em;
}
  
.complex-payment-delivery-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 15px;
    margin-right: 5px;
  }
  
.complex-section-block {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 5px;
    background-color: #f9f9f9;
}
  
.complex-section-title {
    text-align: left;
    margin-bottom: 8px;
    font-size: 1.2em;
    color: #333;
}
  
.complex-section-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
  
.complex-section-list-item {
    margin-bottom: 5px;
    font-size: 0.8em;
    color: #666;
}
  
.complex-contact-description {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.8em;
    color: #666;
}

.product-page-container-xyz {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}
  
.product-details-section-xyz {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
}
  
.product-detail-section-title-xyz {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}
  
.product-detail-item-xyz {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}
  
.product-question-xyz {
    font-weight: bold;
    color: #444;
}
  
.product-answer-xyz {
    text-align: right;
    color: #666;
}
  
.important-xyz {
    font-weight: bold;
    color: #000;
}
  
.less-important-xyz {
    font-size: 0.9em;
    color: #888;
}

.review-section-complex, .question-section-complex {
    margin: 20px 0;
}
  
.review-textarea-complex, .question-textarea-complex {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #444;
    background-color: #f4f4f4;
    color: #333;
    resize: vertical;
    font-size: 1em;
}
  
.review-submit-button, .question-submit-button {
    padding: 12px 24px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1em;
}
  
.review-submit-button:hover, .question-submit-button:hover {
    background-color: #555;
}
  
.review-submission-status, .question-submission-status {
    margin-top: 10px;
    color: #4CAF50;
    font-size: 1em;
}  

.no-product-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
}
  
.no-product-info-message {
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    background: linear-gradient(90deg, #000000, #666666);
    background-clip: text; 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    animation: fadeIn 3s ease-in-out infinite, moveUp 3s ease-in-out infinite;
}

  
.additional-info {
    margin-top: 20px;
    font-size: 1.1em;
    color: #555;
    text-align: center;
    animation: fadeIn 3.5s ease-in-out 3s forwards;
    opacity: 0;
}
  
.loading-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
  
.loading-dots span {
    font-size: 24px;
    animation: blink 3.5s infinite;
    color: #555;
}
  
.loading-dots span:nth-child(2) {
    animation-delay: 0.3s;
}
  
.loading-dots span:nth-child(3) {
    animation-delay: 0.6s;
}
  
@keyframes fadeIn {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
}
  
@keyframes moveUp {
    0%, 100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(0);
    }
}
  
@keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
}

.doc__container--mainWrapper {
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
  
.doc__header--mainTitle {
    font-size: 2.2em;
    color: #343a40;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
}
  
.doc__section--fridges, 
.doc__section--freezers, 
.doc__section--washingMachines, 
.doc__section--dryers, 
.doc__section--cookingSurfaces, 
.doc__section--ovens, 
.doc__section--transportation, 
.doc__section--electricIssues, 
.doc__section--userResponsibility {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #dee2e6;
}
  
.doc__header--sectionTitle {
    font-size: 1.8em;
    color: #495057;
    margin-bottom: 15px;
    font-weight: 600;
}
  
.doc__text--bodyText {
    font-size: 1.1em;
    line-height: 1.6;
    color: #6c757d;
    margin-bottom: 15px;
    text-align: justify;
}
  
  

@media (max-width: 1100px){
    body{
        font-size: 16px;
    }

    .full-item > div{
        padding-top: 15px;
        margin: 8% auto;
        width: 500px;
    }

    .header-inner .shop-cart {
        top: 40px;
        width: 400px;
        padding: 10px;
    }

    .Home-6-review {
        width: 300px;
        height: 300px;
    }

    .Home-6-review img {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }

    .About-4-item, .Home-9-item {
        width: 300px;
        height: 200px;
        padding: 10px;
        margin-bottom: 10px;
    }
}

@media (max-width: 920px){
    body{
        font-size: 15px;
    }

    .full-item > div{
        width: 450px;
    }

    .item .add-to-cart,
    .full-item .add-to-cart {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .item .question-mark,
    .full-item .question-mark {
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
    }

    .header-inner .shop-cart {
        top: 35px;
        width: 350px;
        padding: 10px;
    }

    .banner-image {
        height: 300px;
    }
  
    .slide img {
        height: 450px;
    }

    .About-2, .Home-5{
        height: 620px;
    }

    .Home-6-review {
        width: 254px;
        height: 320px;
    }

    .Home-6-review img {
        width: 50px;
        height: 50px;
        margin-bottom: 5px;
    }

    .Contacts-3-1, .Home-7-1{
        width: 100%;
        height: 50vh;
    }

    .About-4-item, .Home-9-item {
        width: 250px;
        height: 220px;
        padding: 5px;
        margin-bottom: 5px;
    }

    .scroll-section-container {
        gap: 10px; 
        margin: 8px 0;
        padding: 8px;
    }
    
    .scroll-section-container button {
        padding: 8px 12px;
    }

    .product-model-text {
        margin-top: 5px;
        margin-bottom: 12px;
    }
      
    .product-info-container {
        margin-bottom: 12px;
    }
      
    .purchase-button {
        width: 250px;
        padding: 8px;
    }
      
    .purchase-reasons-container {
        margin-top: 20px;
    }
    
    .reasons-title {
        margin-bottom: 10px;
    }
    
    .reason-item .icon {
        margin-bottom: 6px; 
    }

    .contact-section {
        margin-top: 15px;
    }
      
    .horizontal-line {
        border-top: 3px solid #555;
        margin-bottom: 15px;
    }
      
    .contact-question {
        margin: 5px 0;
    }
    
    .contact-form {
        margin: 10px 0;
    }

    .complex-payment-delivery-container {
        gap: 10px;
      }
      
    .complex-section-block {
        padding: 5px;
    }
      
    .complex-section-title {
        margin-bottom: 5px;
    }
      
    .complex-contact-description {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media only screen and (max-width: 768px) {
    body{
        font-size: 14px;
    }

    .header-links {
        position: relative;
    }

    .burger-icon {
        display: block;
        cursor: pointer;
        font-size: 1.5rem;
        margin-left: 10px;
    }

    .burger-menu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #333;
        padding: 15px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        display: none;
        z-index: 9999;
    }

    .burger-menu a {
        display: block;
        font-size: 1.5em;
        color: #fff;
        padding: 5px 0;
        transition: color 0.3s ease;
    }

    .burger-menu a:hover {
        color: #888;
    }

    .burger-menu.active {
        display: block;
    }

    .item .add-to-cart,
    .full-item .add-to-cart {
        width: 25px;
        height: 25px;
        line-height: 25px;
        font-weight: 500;
    }

    .item .question-mark,
    .full-item .question-mark {
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin-right: 5px;
    }

    .categories div{
        padding: 9px 18px;
        margin-top: 9px;
        margin-bottom: 9px;
        margin-right: 9px;
    }

    .header-inner .shop-cart {
        top: 38px;
        width: 300px;
    }

    .header-2 {
        height: 40px;
    }
    
    .header-2-link {
        font-size: 0.9rem;
        padding: 3px;
    }

    #search-input {
        padding: 5px;
    }
    
    .header-2-1 a {
        position: absolute;
        right: 5px;
        top: 3px;
        cursor: pointer;
    }

    .header-2-1-img {
        width: 20px;
        height: 20px;
        transition: transform 0.5s ease;
    }
    
    .banner-image {
        height: 300px;
    }

    .banner-text {
        font-size: 0.8em;
    }

    .close-btn {
        top: 1px;
        right: 1px;
    }
  
    .slide img {
        height: 380px;
    }
      
    .prev-slide {
        left: 15px;
    }
      
    .next-slide {
        right: 15px;
    } 
      
    .text-overlay p {
        font-size: 0.8rem;
    }

    .Home-3, .About-3 {
        flex-direction: column;
        align-items: center;
    }

    .Home-3-item, .About-3-item {
        width: 75%;
        margin-bottom: 20px;
    }

    .Home-3 img, .About-3 img{
        width: 100%;
        border: none;
    }

    .About-3-txt, .Home-3-txt{
        font-size: 1.2rem;
        padding: 5px;
    }

    .About-2, .Home-5 {
        flex-direction: column; 
        height: auto; 
    }

    .About-2-img, .Home-5-img {
        display: none; 
    }

    .About-2-txt, .Home-5-txt {
        width: 100%; 
    }

    .Contacts-2 {
        flex-direction: column;
      }
      
    .Contacts-2-1,
    .Contacts-2-2 {
        width: 100%;
        border: none;
        padding: 0;
    }
      
    .Contacts-2-1 {
        margin-bottom: 20px; 
    }
      
    .Contacts-2-2 {
        border-left: none; 
    }

    .step {
        border: none;
        margin-bottom: 15px;
    }
    
    .Home-4-number {
        font-size: 4rem;
    }
    
    .Home-4-step-image {
        width: 80%;
        border: 1px solid #333;
    }
    
    .Home-4-step-text {
        font-size: 1rem;
    }

    .Home-6-review {
        width: 290px;
        height: 280px;
    }

    .Home-6-review img {
        width: 35px;
        height: 35px;
        margin-bottom: 5px;
    }

    .Contacts-3-1, .Home-7-1{
        width: 100%;
        height: 40vh;
    }

    .Service-2-1,
    .Service-3-1 {
        flex-direction: column;
        align-items: center; 
    }

    .Service-2-1-1,
    .Service-2-1-2,
    .Service-3-1-1,
    .Service-3-1-2 {
        width: 95%; 
        margin: 0 auto; 
    }

    .Home-8 {
        margin-top: 10px;
    }
      
    .Home-8 h2 {
        font-size: 1rem;
        margin-bottom: 3px;
    }

    .About-4-item, .Home-9-item {
        width: 300px;
        height: 220px;
        padding: 8px;
        margin-bottom: 10px;
    }

    .Service-2 p, .Service-3 p, .Service-4 p{
        font-weight: 700;
        margin-bottom: 5px;
    }
    
    .Service-2-1-1, .Service-2-1-2,
    .Service-3-1-1, .Service-3-1-2 {
        padding: 8px;
        margin-bottom: 8px;
    }
    
    .Service-2-1-1 h2, .Service-2-1-2 h2,
    .Service-3-1-1 h2, .Service-3-1-2 h2{
        font-size: 1.2rem;
        margin-bottom: 3px;;
    }

    .tab {
        font-size: 0.8rem;
        padding: 3px 5px;
    }

    #section1 {
        width: 100%;
        display: flex;
        flex-direction: column; 
        background-color: #f4f4f4;
        margin-bottom: 30px;
    }
    
    .section1-part1, 
    .section1-part2, 
    .section1-part3{
        min-width: 100%;
    }

    .section1-part1{
        text-align: center;
    }

    .main-image img {
        width: 70%;
        height: auto;
    }
      
    .thumbnail-gallery {
        gap: 15px;
        margin-top: 5px;
    }
      
    .thumbnail img {
        max-width: 120px;
        height: 80%;
    }
      
    scroll-section-container {
        gap: 8px; 
        margin: 5px 0;
        padding: 5px;
    }
    
    .scroll-section-container button {
        font-size: 10px;
        padding: 6px 18px;
    }
      
    .product-info-container {
        margin-bottom: 12px;
    }
      
    .purchase-button {
        padding: 8px 12px;
    }
      
    .purchase-reasons-container {
        margin-top: 15px;
    }
}
      
@media only screen and (min-width: 769px) {
    .burger-icon {
        display: none;
    }

    .burger-menu {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 620px){
    body{
        font-size: 13px;
    }

    .burger-menu {
        padding: 8px;
    }

    .categories div{
        padding: 10px 18px;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-right: 8px;
    }

    .full-item > div{
        margin: 3px auto;
        padding-top: none;
        width: 350px;
    }

    .full-item img{
        width: 300px;
    }

    .main-items h2, .full-item h2,
    .main-items p, .full-item p {
        margin: 5px 10px;
        color: #333;
    }

    .header-logo {
        font-size: 1.5rem;
        font-weight: 800;
    }

    .header-inner .shop-cart {
        top: 30px;
        width: 250px;
        padding: 5px;
    }
    
    .header-inner .shop-cart .item {
        color: #333;
        width: 100%;
        float: left;
        margin-bottom: 5px;
    }
    
    .header-inner .shop-cart .item img {
        margin-right: 5px;
    }
    
    .header-inner .shop-cart .item h2 {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    
    .header-inner .shop-cart .item b {
        font-size: 0.8rem;
        color: #000;
        font-weight: 800;
    }
    
    .header-inner .shop-cart .no-orders {
        font-size: 1.2rem;
    }
    
    .header-inner .shop-cart .item .delete-icon {
        font-size: 0.8rem;
        top: -15px;
    }
    
    .header-inner .shop-cart .item .delete-icon:hover {
        color: #d83030;
        transform: scale(1.5);
    }
    
    .header-inner .shop-cart .sum {
        font-size: 1rem;
    }

    .header-2-link {
        font-size: 0.8rem;
        padding: 1px;
    }

    .item .add-to-cart,
    .full-item .add-to-cart {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-weight: 400;
    }

    .item .question-mark,
    .full-item .question-mark {
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
    }

    .main-items h2, .full-item h2,
    .main-items p, .full-item p,
    .main-items b, .full-item b{
        margin: 5px 10px;
        font-size: 0.6rem;
    }

    .order-form {
        padding: 10px;
    }
    
    .order-form input {
        padding: 5px;
        margin-top: 3px;
        margin-bottom: 10px;
    }
    
    .order-form button {
        padding: 3px;
    }

    .titles {
        font-size: 1.8rem;
    }
  
    .slide img {
        height: 270px;
    }

    .text-overlay {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        background-color: #333;
        padding: 3px;
    }

    .prev-slide, .next-slide {
        font-size: 1.2rem;
        padding: 6px, 12px;
    }
      
    .prev-slide {
        left: 10px;
    }
      
    .next-slide {
        right: 10px;
    } 

    .Contacts-2{
        border: none;
    }

    .Contacts-2-1 label {
        margin-bottom: 3px;
    }
    
    .Contacts-2-1 input,
    .Contacts-2-1 textarea {
        padding: 3px;
        margin-bottom: 5px;
    }
    
    .Contacts-2-1 button {
        padding: 3px 5px;
    }
    
    .Contacts-2-2 {
        border-left: none; 
    }
    
    .Contacts-2-2 img{
        margin-left: 3px;
        height: 15px;
    }

    .About-2-txt, .Home-5-txt {
        font-size: 1rem;
    }

    .About-5 {
        height: 200px;
        padding: 8px 0;
    }
    
    .About-5 h2 {
        font-size: 1.2rem;
        margin-bottom: 5px;
    }
    
    .About-5 p {
        margin-bottom: 5px;
    }
    
    .About-5 a {
        padding: 5px 8px;
        font-size: 0.9rem;
    }

    .Home-6-review {
        width: 238px;
        height: 330px;
    }

    .Home-6-review img {
        width: 35px;
        height: 35px;
        margin-bottom: 5px;
    }

    .question {
        font-size: 0.9rem;
        border-radius: 5px;
        padding: 8px;
        margin-bottom: 3px;
    }
      
    .answer {
        font-size: 0.8rem;
        padding: 5px;
        margin-bottom: 5px;
    } 

    .Contacts-3-1, .Home-7-1{
        height: 35vh;
    }

    .About-4-item, .Home-9-item {
        width: 230px;
        height: 220px;
        padding: 8px;
        margin-bottom: 10px;
    }

    .footer-item {
        margin-bottom: 5px;
    }
    
    .footer-item h3 {
        font-size: 1rem;
    }
    
    .footer-item ul li, .footer-item a{
        margin-bottom: 3px;
    }
    
    .footer-inner p{
        margin-bottom: 3px;
    }

    .main-image img {
        width: 70%;
        height: auto;
    }
      
    .thumbnail-gallery {
        gap: 5px;
        margin-top: 5px;
    }
      
    .thumbnail img {
        max-width: 60px;
        height: 80%;
    }

    .product-info-container {
        margin-bottom: 8px;
    }
      
    .purchase-button {
        font-size: 28px;
        padding: 8px 12px;
    }
      
    .purchase-reasons-container {
        margin-top: 8px;
    }
    
    .reasons-title {
        font-size: 24px;
    }
    
    .reason-item .icon {
        font-size: 28px; 
        margin-bottom: 3px; 
    }

    .contact-section {
        margin-top: 8px;
    }
      
    .horizontal-line {
        margin-bottom: 20px;
    }
    
    .contact-form {
        margin: 8px 0;
    }

    .complex-payment-delivery-container {
        gap: 10px;
    }
      
    .complex-section-block {
        padding: 8px;
    }
      
    .complex-section-title {
        margin-bottom: 8px;
        font-size: 1.2em;
    }
}

@media (max-width: 480px){
    body{
        font-size: 12px;
    }

    .categories div{
        padding: 8px 16px;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 8px;
    }

    .full-item > div{
        margin: 3px auto;
        width: 260px;
    }

    .full-item img{
        width: 250px;
    }

    .header-inner .shop-cart {
        top: 30px;
        width: 200px;
        padding: 3px;
    }

    .item .question-mark,
    .full-item .question-mark {
        margin-right: 8px;
    }

    .header-2-1 {
        display: none;
    }

    .header-2-link {
        padding: 1px 3px;
        border: none;
        border-radius: 50px;
        background: linear-gradient(145deg, #444, #222);
        box-shadow: 5px 5px 15px #222, -5px -5px 15px #444;
        font-size: 0.8rem;
        font-weight: 800;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .header-2-link:hover{
        background: linear-gradient(145deg, #222, #444);
        box-shadow: 5px 5px 15px #111, -5px -5px 15px #555;
    }

    .header-2-link:active{
        background: linear-gradient(145deg, #111, #555);
        box-shadow: inset 5px 5px 15px #111, inset -5px -5px 15px #555;
    }

    .header-2-link.left {
        margin-right: auto;
    }
      
    .header-2-link.right {
        margin-left: auto;
    }
    
    .banner-image {
        height: 300px;
    }

    .banner-text {
        font-size: 0.9rem;
    }

    .close-btn {
        font-size: 1.5rem;
    }

    .titles {
        font-size: 1.5rem;
    }
  
    .slide img {
        height: 200px;
    }

    .text-overlay {
        padding: 2px;
    }
      
    .prev-slide, .next-slide {
        font-size: 1rem;
        padding: 1px, 3px;
    }

    .prev-slide {
        left: 5px;
    }
      
    .next-slide {
        right: 5px;
    }

    .About-1 img, .Contacts-1 img, .Service-1 img, .Catalog-1 img{
        max-height: 35vh;
    }
    
    .About-1-txt, .Contacts-1-txt, .Service-1-txt, .Catalog-1-txt{
        font-size: 0.75rem; 
    }
    
    .About-1-txt h1, .Contacts-1-txt h1, .Service-1-txt h1, .Catalog-1-txt h1{
        font-size: 1rem;
        margin-bottom: none;
    }

    .Service-2 p, .Service-3 p, .Service-4 p{
        font-weight: 600;
        margin-bottom: 3px;
    }
    
    .Service-2-1-1, .Service-2-1-2,
    .Service-3-1-1, .Service-3-1-2 {
        padding: 8px;
        margin-bottom: 8px;
    }
    
    .Service-2-1-1 h2, .Service-2-1-2 h2,
    .Service-3-1-1 h2, .Service-3-1-2 h2{
        font-size: 0.8rem;
        margin-bottom: 3px;;
    }

    .Home-6-review {
        width: 340px;
        height: 280px;
    }

    .Home-6-review img {
        width: 50px;
        height: 50px;
        margin-bottom: 8px;
    }

    .Contacts-3-1, .Home-7-1{
        width: 100%;
        height: 40vh;
    }

    .About-2-txt, .Home-5-txt {
        font-size: 0.8rem;
    }

    .Home-3-item, .About-3-item {
        width: 80%;
        margin-bottom: 10px;
    }

    .About-3-txt, .Home-3-txt{
        font-size: 0.9rem;
        padding: 5px;
    }

    .About-4-item, .Home-9-item {
        width: 175px;
        height: 180px;
        padding: 3px;
        margin-bottom: 5px;
    }

    .About-4-item h1, .Home-9-item h1{
        font-size: 1.5rem;
    }
    
    .About-4-item p, .Home-9-item p{
        font-size: 0.8rem;
    }

    .tab {
        font-size: 0.7rem;
        padding: 2px 3px;
    }

    .main-image img {
        width: 80%;
        height: auto;
    }

    scroll-section-container {
        gap: 3px; 
        margin: 3px 0;
        padding: 3px;
    }
    
    .scroll-section-container button {
        padding: 3px 8px;
    }
      
    .thumbnail-gallery {
        gap: 3px;
        margin-top: 3px;
    }
      
    .product-info-container {
        margin-bottom: 3px;
    }
      
    .purchase-button {
        padding: 3px 8px;
    }
      
    .purchase-reasons-container {
        margin-top: 3px;
    }
    
    .reason-item .icon {
        margin-bottom: 3px; 
    }

    .contact-section {
        margin-top: 3px;
    }
      
    .horizontal-line {
        margin-bottom: 20px;
    }
    
    .contact-form {
        margin: 3px 0;
    }

    .complex-payment-delivery-container {
        gap: 5px;
    }
      
    .complex-section-block {
        padding: 5px;
    }
      
    .complex-section-title {
        margin-bottom: 5px;
    }
}

@media (max-width: 360px){
    body{
        font-size: 10px;
    }

    .item .add-to-cart,
    .full-item .add-to-cart {
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-weight: 400;
    }

    .item .question-mark,
    .full-item .question-mark {
        width: 18px;
        height: 18px;
        line-height: 18px;
        margin-right: 5px;
    }

    .categories div{
        padding: 6px 14px;
        margin-bottom: 3px;
        margin-right: 3px;
    }

    .full-item > div{
        margin: 3% auto;
        width: 300px;
    }

    .banner-image {
        height: 200px;
    }

    .banner-text {
        font-size: 0.6rem;
    }

    .close-btn {
        font-size: 1rem;
    }

    .header-inner .shop-cart {
        width: 220px;
        padding: 3px;
    }

    .header-inner .shop-cart .no-orders {
        font-size: 0.9rem;
    }

    .slide img {
        height: 350px;
    }
      
    .text-overlay p {
        font-size: 0.5rem;
    }
    
    .Home-4-number {
        font-size: 2rem;
    }
    
    .Home-4-step-text {
        font-size: 0.9rem;
    }

    .titles {
        font-size: 1rem;
    }
  
    .slide img {
        height: 190px;
    }

    .prev-slide, .next-slide {
        font-size: 1.2rem;
        padding: 6px
    }
    
    .About-1-txt, .Contacts-1-txt, .Service-1-txt, .Catalog-1-txt{
        font-size: 0.7rem; 
    }

    .About-5{
        height: 250px;
        padding: 3px, 0;
    }
    
    .About-5 h2 {
        font-size: 1rem;
        margin-bottom: 5px;
    }
    
    .About-5 p {
        font-size: 0.7rem;
        margin-bottom: 5px;
    }
    
    .About-5 a {
        padding: 5px 8px;
        font-size: 0.8rem;
    }
    
    .Home-6-review {
        width: 270px;
        height: 300px;
    }

    .Home-6-review img {
        width: 45px;
        height: 45px;
        margin-bottom: 8px;
    }

    .Home-6-review p {
        font-size: 0.9rem;
    }
    
    .Home-6-review h4 {
        font-size: 1.2rem;
    }

    .About-2-txt, .Home-5-txt {
        font-size: 0.8rem;
    }

    .About-4-item, .Home-9-item {
        width: 270px;
        height: 180px;
        padding: 3px;
        margin-bottom: 5px;
    }

    .About-4-item h1, .Home-9-item h1{
        font-size: 1.5rem;
    }
    
    .About-4-item p, .Home-9-item p{
        font-size: 0.9rem;
    }

    .main-image img {
        width: 80%;
        height: auto;
    }

    scroll-section-container {
        gap: 0;
        margin: 0;
        padding: 0;
    }
    
    .scroll-section-container button {
        padding: 0;
        border: none;
    }
      
    .thumbnail-gallery {
        gap: 2px;
        margin-top: 2px;
    }
      
    .product-info-container {
        margin-bottom: 3px;
    }
      
    .purchase-button {
        padding: 3px 8px;
    }
      
    .purchase-reasons-container {
        margin-top: 3px;
    }
    
    .reason-item .icon {
        margin-bottom: 3px; 
    }

    .contact-section {
        margin-top: 3px;
    }
      
    .horizontal-line {
        margin-bottom: 20px;
    }
    
    .contact-form {
        margin: 3px 0;
    }

    .complex-payment-delivery-container {
        gap: 5px;
    }
      
    .complex-section-block {
        padding: 5px;
    }
      
    .complex-section-title {
        margin-bottom: 5px;
    }
}